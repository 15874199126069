import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this._forceOpenModal();
  }

  _forceOpenModal() {
    const modalButton = this.scope.element.querySelector('[data-force-open="true"]');
    if (modalButton) {
      modalButton.click();
    }
  }
}
