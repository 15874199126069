import { Controller } from "stimulus"
import $ from 'jquery'
import('select2/dist/css/select2.min.css')
import('select2-bootstrap4-theme/dist/select2-bootstrap4.min.css')

import Select2 from "select2"

export default class extends Controller {
  static targets = [ "itemId", "divItemAsBorrower" ]

  connect() {
    const displayCheckboxEmail = (option) => {
      const checkboxEmail = document.querySelector('[data-target="select2.checkboxEmail"]')
      if (option.dataset.ownsEmail == 'true' && option.dataset.type == 'contact') {
        checkboxEmail.classList.toggle('hide', false)
      } else {
        checkboxEmail.classList.toggle('hide', true)
        checkboxEmail.querySelector('input[type="checkbox"]').checked = false;
      }
    }

    const select = document.querySelector('select#reminder_borrower_id')
    if (select) {
      const option = select.options[select.selectedIndex]
      displayCheckboxEmail(option)
    }

    const selectItemInUrl = () => {
      const url = new URL(window.location)
      const searchParams = new URLSearchParams(url.search)
      const itemIdSelectedInUrl = searchParams.get('item_id')
      if (itemIdSelectedInUrl) {
        $('.select2-item-as-borrower').val(itemIdSelectedInUrl).trigger('change')
        document.querySelector('[data-target="select2.divAddItem"]').classList.toggle('hide', true)
        document.querySelector('[data-target="form-as-borrower.submit"]').classList.toggle('hide', false)
      }
    }

    const toggleAddItemButton = () => {
      if ($('.select2-owner').find(':selected')[0]) {
        const isOwnerSelectedAContact = $('.select2-owner').find(':selected')[0].dataset.class == 'contact'
        if (isOwnerSelectedAContact) {
          const divAddItemButton = document.querySelector('[data-target="select2.divAddItem"]')
          divAddItemButton.classList.toggle('hide', !isOwnerSelectedAContact)
          const ownerSelected = $('.select2-owner').find(':selected')[0]
          divAddItemButton.querySelector('a').setAttribute('href', `/items/new?from=as_borrower&contact_id=${ownerSelected.value}`)
        }
      }
    }

    $('.select2').select2({
      width: '100%',
      theme: 'bootstrap4'
    })

    $('.select2.item-as-owner').on('select2:select', function (e) {
      const itemIdSelected = e.params.data.id;
      const inputItemId = document.querySelector('[data-target="select2.itemId"]')
      inputItemId.value = itemIdSelected
    });

    $('.select2.select2-borrower').on('select2:select', function (e) {
      const option =  e.params.data.element;
      displayCheckboxEmail(option);
    });

    const divItemAsBorrower = document.querySelector('[data-target="select2.divItemAsBorrower"]')
    const divSubmit = document.querySelector('[data-target="form-as-borrower.submit"]')

    const refreshItems = () => {
      const ownerSelected = $('.select2-owner').find(':selected')[0]
      toggleAddItemButton();
      if (divSubmit) {
        divSubmit.classList.toggle('hide', true)
      }

      if (ownerSelected) {
        divItemAsBorrower.classList.toggle('hide', false)
        fetch(`/items/index_select2?owner_id=${ownerSelected.value}`, { headers: { 'Accept': 'text/plain' } })
          .then(response => response.json())
          .then((data) => {
            $('.select2-item-as-borrower').select2().empty()
            if (data.results && data.results.length > 0) {
              $('.select2-item-as-borrower').select2({
                data: data.results,
                theme: 'bootstrap4',
                width: '100%'
              })
              selectItemInUrl();
            }
          })
      } else {
        if (divItemAsBorrower) {
          divItemAsBorrower.classList.toggle('hide', true)
        }
        $('.select2-item-as-borrower').val(null).trigger('change');
      }
    }

    refreshItems();

    $('.select2-owner').on('select2:select', function (e) {
      refreshItems();
    });

    $('.select2-item-as-borrower').on('select2:select', function (e) {
      const itemIdSelected = $('.select2-item-as-borrower').find(':selected')[0].value
      if (itemIdSelected) {
        divSubmit.classList.toggle('hide', false)
      } else {
        divSubmit.classList.toggle('hide', true)
      }
    });
  }
}
