import 'core-js/stable';
import 'regenerator-runtime/runtime';
import "../components/flashes";

import 'bootstrap';

// Stimulus configuration
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import { initUpdateNavbarOnScroll } from '../components/scroll_navbar';
import { initNotifications } from '../components/init_notifications';

initUpdateNavbarOnScroll();
initNotifications();
