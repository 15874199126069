const initNotifications = () => {
  const navbar = document.querySelector('.navbar-default');
  if (navbar) {
    window.addEventListener('DOMContentLoaded', () => {
      const options = {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      }
      fetch(`/users/notifications`, options).then((response) => { return response.json() }).then(display)
    })
  }
}


const display = (data) => {
  if (data.nb_friend_requests > 0) {
    const divNotifFriends = document.querySelectorAll('.notif-friend-requests')
    divNotifFriends.forEach((element) => {element.innerHTML = data.nb_friend_requests})
  }
  if (data.nb_items_to_validate > 0) {
    const divNotifItems = document.querySelectorAll('.notif-validate-items')
    divNotifItems.forEach((element) => {element.innerHTML = data.nb_items_to_validate})
  }
}

export { initNotifications };
