import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "favorite", "notFavorite" ]

  toggleFavorite() {
    const itemId = this.data.get('id')

    const toggleIcon = () => {
      this.notFavoriteTarget.classList.toggle('hide')
      this.favoriteTarget.classList.toggle('hide')
    }

    const displayResults = (response) => {
      if (response.action == "deleted") {
        this.notFavoriteTarget.classList.remove('hide')
        this.favoriteTarget.classList.add('hide')
      } else {
        this.notFavoriteTarget.classList.add('hide')
        this.favoriteTarget.classList.remove('hide')
      }
    }

    const toggleFavoriteAjax = (item_id) => {
      const options = {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      }
      fetch(`/items/${item_id}/toggle_favorite_ajax`, options).then((response) => {
        if(response.ok) {
          response.json().then(displayResults);
        }
      })
    }

    toggleIcon()
    event.preventDefault()
    toggleFavoriteAjax(itemId)
  }
}








